import { getHeaderFooterLinks, getLocations, getMicrocopy, getPpm365Locations } from "./graphql";
import { getPaToken } from "./pelicanAccount";

export const getAllPageData = async (locale, defaultLocale): Promise<GlobalState> => {
  const microcopy = await getMicrocopy(locale, defaultLocale);
  const footerLinks = await getHeaderFooterLinks(locale, defaultLocale);
  const locations = await getLocations(locale, defaultLocale);
  const ppm365Locations = await getPpm365Locations(locale, defaultLocale);
  const disasters = await getDisasterResponseList(locale);
  const recentJournals = await getRecentJournals(locale);
  const tripCounts = await getCurrentTripCounts(locale);
  const ppm365Updates = await getRecentPpm365Updates(locale);

  return {
    microcopy,
    locations,
    disasters,
    recentJournals,
    footerLinks,
    tripCounts,
    ppm365Updates,
    ppm365Locations,
  }
}

/**
 * 
 * @param {string} locale - language locale abbreviation
 * @returns {DisasterResponse[]} - Array of active disaster responses
 */
export const getDisasterResponseList = async (locale: string): Promise<DisasterResponse[]> => {
  // return await (await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/disaster-response`)).json();
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/disaster/`)).json();
}

export const getPastDisasterResponseList = async (locale: string): Promise<DisasterResponse[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/disaster/past`)).json();
}

export const getRecentDisasterResponseUpdates = async(locale: string, count = 5): Promise<JournalEntry[]> => {
  return (await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/disaster/updates?count=${count}`)).json()).entryList;
}

export const getRootsLocation = async (locale: string, rootsId: string): Promise<RootsLocation> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/roots/${rootsId}`)).json()
}

export const getPpm365Location = async(locale: string, rootsId: string): Promise<RootsLocation> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/roots/${rootsId}?ppm365=true`)).json()
}

export const getResources = async (locale: string): Promise<MissionTripResourceList> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/resources/`)).json()
}

export const getDisasterResponseData = async (locale: string, slug: string): Promise<DisasterResponseFull | null> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/disaster/${slug}`);

  if (response.ok) {
    const json = await response.json();

    if (json === 404) {
      return null
    }
    else {
      return json;
    }
  }
  else {
    return null
  }

}

export const getRecentJournals = async (locale: string): Promise<RecentJournal[]> => {
  // return await (await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/recent-journals`)).json();
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/recent`)).json();
}

export const getCurrentTripCounts = async (locale: string): Promise<{ attendees: number, trips: number }> => {
  // return await (await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/trip-counts`)).json();
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/current/count`)).json();
}

export const getInitialTripSearchParameters = async (locale: string): Promise<{ costList: number[], durationList: number[] }[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/dates/parameters`)).json();
}

export const getSmallGroupDates = async (locale: string): Promise<SmallGroupTripSearchResult[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/dates`)).json();
}

export const getTermsConditions = async (locale: string): Promise<string> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/terms`)).json();
}

export const getPpm365Updates = async (locale: string, rootsLocationId: string): Promise<Ppm365UpdatesResult> => {
  if (rootsLocationId) {
    return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/ppm365/${rootsLocationId}`)).json();
  } else {
 return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/ppm365/?limit=5`)).json();

}
}

export const getRecentPpm365Updates = async(locale: string, limit = 5): Promise<Ppm365Update[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/ppm365/?limit=${limit}`)).json();
}

export const getParticipantFundraisingData = async (tripId: string, participantId: string): Promise<ParticipantFundraisingData | number> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/fundraising/${tripId}/${participantId}`)).json();
}

export const getTripFundraisingData = async (tripId: string): Promise<TeamFundraisingData | number> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/fundraising/${tripId}`)).json();
}

export const getBlogPosts = async (): Promise<WpBlogPost[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/blog`)).json();
}

export const getBlogPostsByAuthor = async (author: string): Promise<WpBlogPost[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/blog/author/${author}`)).json();
}

export const getBlogPost = async (slug: string): Promise<{ post: WpBlogPostFull }> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/blog/post/${slug}`)).json();
}

export const getLocationJournalData = async (rootsId: string): Promise<{ journals: PastTripJournal[] }> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/location/${rootsId}`)).json();
}

export const getTripDateList = async (props): Promise<CustomTripSearchResult[]> => {
  const getTripList = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/dates/trip-list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })

  let results: CustomTripSearchResult[] = await getTripList.json();

  return results;
}

export const tripDateSearch = async (searchParams: TripSearchParameter[]): Promise<TripSearchResponse> => {
  const getSearchResults = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/dates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ searchJson: JSON.stringify(searchParams ?? []) }),
  });

  const results: TripSearchResponse = (await getSearchResults.json()) as unknown as TripSearchResponse;


  return results;

}

export const tripLocationSearch = async (searchValues: LocationTripSearchProps) => {

  const submitValues = JSON.parse(JSON.stringify(searchValues));

  const response = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/dates/location`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(submitValues)
  });

  const results: TripSearchResponse = (await response.json()) as unknown as TripSearchResponse;


  return results

}

export const getTripJournalEntry = async (entryId: number): Promise<TripJournalEntry | number> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journal/${entryId}`)).json();
}

export const getTripJournal = async (tripId: number): Promise<TripJournal | number> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/${tripId}`)).json();
}

export const getCurrentJournals = async (): Promise<CurrentTripJournal[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/current`)).json();
}

export const getCurrentJournalsMap = async (): Promise<TripJournalMapData> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals-map`)).json();
}

export const getDisastersMap = async (): Promise<TripJournalMapData> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/disaster-map`)).json();
}

export const getRecommendedJournals = async (): Promise<PastTripJournal[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/recommended`)).json();
}

export const getDisasterJournals = async (): Promise<TripJournalMapData[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/disaster`)).json();
}

export const getDisasterCurrentPastJournals = async (): Promise<TripJournalMapData[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/disastercurrentpast`)).json();
}

export const getDisasterJournalsMap = async (): Promise<TripJournalMapData> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals-map`)).json();
}

export const postOnceClickRenewal = async (id: string) => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/renewal-response/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: id})
  }))
}

export const putUserSelectedLanguage = async (selectedLanguage: string) => {
  const token = getPaToken()
  
  if (token) {
    return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/user`, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        field: 'selectedLanguage',
        value: selectedLanguage,
        sqltype: 'varchar',
        authenticatedUserID: token.ID
      })
    }));
  }
}
