import jwtDecode from "jwt-decode";
import { getCookie, setCookie } from "./misc";

export interface SignUpForm {
    email: string,
    password: string,
    firstName: string,
    lastName: string
}

export interface SignInFormType {
    email: string,
    password: string
}

export interface PelicanAccountToken {
    ID: number,
    GROUPID: number,
    exp: number,
    name?: string,
    id?: string
}

export function getPaCookie() {
    const cookie = decodeURI(getCookie('XSRF-TOKEN'))
    return cookie
}

function setPaCookie(token: string) {
    setCookie("XSRF-TOKEN", token)
}

export function getLoopbackAuthHeader() {
    return {
        "Authorization": "Bearer " + getPaCookie()
    }
}

export function getPaToken(): PelicanAccountToken | undefined {
    const token = getPaCookie();

    if (token) {
        return jwtDecode(getPaCookie())
    }
    else {
        return undefined
    }
    
}

export function isPaTokenExpired(token: PelicanAccountToken): boolean {
    if (!token.exp) {
        return true
    }
    else {
        return token.exp < new Date().valueOf() / 1000
    }
}

export const checkSignIn = async () => {
    return await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/user`, {
      credentials: 'include'
    })).json();
  }

  export interface CreateAccountResponse {
    result: CreateAccountResults
  }
  export type CreateAccountResults = "success" | "error" | "account_exists"

  
  const createAccount = async (data: SignUpForm): Promise<CreateAccountResponse> => {
    const result =  await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/users`, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
          }
    })

    if (result.ok) {
        return result.json()
    }
    else {
        const body = await result.json()
        if (body.error) {
            throw body.error
        }
        else {
            throw new Error("Unknown problem creating account: " + result.statusText)
        }
        
    } 

  }
  
  
export const PelicanAccountApi = {
    checkSignIn: checkSignIn,
    createAccount: createAccount,
    getPaCookie: getPaCookie,
    setPaCookie: setPaCookie

}